import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Added useNavigate
import { signOut } from 'firebase/auth'; // Added signOut import
import { auth } from '../utils/firebase'; // Ensure your Firebase auth is properly imported
import { useDispatch } from 'react-redux';
import { logoutClients } from '../Redux/slices/clientSlice';
import { logoutUser } from '../Redux/slices/userSlice';

const NavBar = () => {
    const navigate = useNavigate(); // Added navigate hook for navigation
    const dispatch = useDispatch();

    const logouthandle = async (e) => {
        e.preventDefault();
        // Check if 'user_id' exists in localStorage
        // if (localStorage.getItem('user_id')) {
        //     localStorage.removeItem('user_id'); // Remove 'user_id' from localStorage
        // }
        // if (localStorage.getItem('oldprofile')) {
        //     localStorage.removeItem('oldprofile'); 
        // }
        // localStorage.clear();
        if (localStorage.getItem('user_id')) {
            localStorage.removeItem('user_id');
        }
        if (localStorage.getItem('oldprofile')) {
            localStorage.removeItem('oldprofile');
        }
        await signOut(auth); // Await added to ensure signOut is complete
        dispatch(logoutClients());
        dispatch(logoutUser())
        navigate("/"); // Navigate to home after sign out
    };

   
    return (
        <div className="bg-gradient-to-l from-green-400 from-12.76% via-sky-500 from-35.58% via-purple-500 from-63.06%  to-fuchsia-500 to-89.1% w-full h-12 flex justify-center align-middle">


            <div className="header-btns-div text-black flex"> {/* Added flex to align buttons */}
                <Link to="/proposal-dashboard">
                <h2 className=" lg:ml-[-1rem]  py-2 text-3xl text-white font-bold">Grant<span className='text-white font-bold'>AI</span>de</h2>
                </Link>
                <div className=" header-btns-left">

                    <Link to="/proposal-dashboard">
                        <button 
                            className="px-1 py-1 w-[95px] text-center cursor-pointer text-[#591c87] bg-[#ebeaf5] rounded-xl shadow-gray-800 shadow-md font-sans md:text-base
                            hover:bg-[#591c87] hover:text-white active:bg-[#591c87] active:shadow-gray-800 active:shadow-md active:translate-y-1 transition-transform"
                            >
                                Dashboard
                            </button>
                    </Link>
                </div>

                <div className='header-btns-right'>
                    <button 
                        className="px-3 py-1 w-[95px] text-center cursor-pointer text-[#591c87] bg-[#ebeaf5] rounded-xl shadow-gray-800 shadow-md font-sans md:text-base sm:p-1
                        hover:bg-[#591c87] hover:text-white active:bg-[#591c87]  active:shadow-gray-800 active:shadow-md active:translate-y-1 transition-transform" 
                        onClick={logouthandle}
                    >
                        Sign Out
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NavBar;