import { useEffect } from "react";

export default function useSaveAffiliateId() {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const affiliateId = urlParams.get('aff_id');

    if (!affiliateId) {
      console.log("No affiliate ID found in the URL.");
      return;
    }

    console.log("Saving affiliate ID:", affiliateId);
    localStorage.setItem('affiliate_id', affiliateId);

    urlParams.delete('aff_id');

    const newRelativePathQuery = window.location.pathname + '?' + urlParams.toString();

    window.history.pushState(null, '', newRelativePathQuery);
  }, [])
}