import React, { createContext, useEffect, useState } from 'react';
import { auth, db } from '../utils/firebase';
import { getDocs, collection, query, where, and } from "firebase/firestore";
// Define the initial state of the context
const initialSubscriptionState = {
  subscriptionState: false,
};

// Create the context
const SubscriptionContext = createContext(initialSubscriptionState);

// Create a provider component
const SubscriptionProvider = ({ children }) => {
  const [subscriptionState, setSubscription] = useState(true);




  useEffect(() => {

    // Check if the user has an active subscription
    auth.onAuthStateChanged(async (user) => {
      if (user) {

        const subscriptionsRef = collection(db, "Subscriptions");
        const q = query(subscriptionsRef, and(where("user_id", "==", user.uid), where("status", "==", "active")));
        const subscriptions = (await getDocs(q)).docs

        if (subscriptions.length > 0) {
          setSubscription(true);
        } else {
          setSubscription(false);
        }
      } else {
        setSubscription(false);
      }
    })

  }, []);

  return (
    <SubscriptionContext.Provider value={{ subscriptionState }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

const useSubscriptionContext = () => {
  const context = React.useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscriptionContext must be used within a SubscriptionProvider');
  }
  return context;
}

export { useSubscriptionContext, SubscriptionProvider }