import React, { useState } from 'react';
import './TermsOfService.css'; // Ensure this path points to the correct CSS file
import imgIntro from '../../assets/images/TermsOfService/Intro.png'
import img1 from '../../assets/images/TermsOfService/1-Overview.png'
import img2 from '../../assets/images/TermsOfService/2-Eligibility.png'
import img3 from '../../assets/images/TermsOfService/3-AccountsAndRegistration.png'
import img4 from '../../assets/images/TermsOfService/4-GeneralPaymentTerms.png'
import img4_1 from '../../assets/images/TermsOfService/4-1.png'
import img4_2 from '../../assets/images/TermsOfService/4-2.png'
import img4_3 from '../../assets/images/TermsOfService/4-3.png'
import img4_4 from '../../assets/images/TermsOfService/4-4.png'
import img4_5 from '../../assets/images/TermsOfService/4-5.png'
import img5_1 from '../../assets/images/TermsOfService/5-1.png'
import img5_2 from '../../assets/images/TermsOfService/5-2.png'
import img5_3 from '../../assets/images/TermsOfService/5-3.png'
import img6 from '../../assets/images/TermsOfService/6-OwnershipProprietaryRights.png'
import img7_1 from '../../assets/images/TermsOfService/7-1.png'
import img7_2 from '../../assets/images/TermsOfService/7-2.png'
import img7_3 from '../../assets/images/TermsOfService/7-3.png'
import img8 from '../../assets/images/TermsOfService/8-ArtificialIntelligence.png'
import img9_1 from '../../assets/images/TermsOfService/9-1.png'
import img9_2 from '../../assets/images/TermsOfService/9-2.png'
import img9_3 from '../../assets/images/TermsOfService/9-3.png'
import img9_4 from '../../assets/images/TermsOfService/9-4.png'
import img9_5 from '../../assets/images/TermsOfService/9-5.png'
import img10 from '../../assets/images/TermsOfService/10-ProhibitedData.png'
import img11 from '../../assets/images/TermsOfService/11-DPA.png'
import img12 from '../../assets/images/TermsOfService/12-MonitoringContent.png'
import img13_1 from '../../assets/images/TermsOfService/13-1.png'
import img13_2 from '../../assets/images/TermsOfService/13-2.png'
import img13_3 from '../../assets/images/TermsOfService/13-3.png'
import img14 from '../../assets/images/TermsOfService/14-ProhibitedConduct.png'
import img15_1 from '../../assets/images/TermsOfService/15-1.png'
import img15_2 from '../../assets/images/TermsOfService/15-2.png'
import img15_3 from '../../assets/images/TermsOfService/15-3.png'
import img15_4 from '../../assets/images/TermsOfService/15-4.png'
import img15_5 from '../../assets/images/TermsOfService/15-5.png'
import img15_6 from '../../assets/images/TermsOfService/15-6.png'
import img15_7 from '../../assets/images/TermsOfService/15-7.png'
import img16 from '../../assets/images/TermsOfService/16-ModificationOfTerms.png'
import img17_1 from '../../assets/images/TermsOfService/17-1.png'
import img17_2 from '../../assets/images/TermsOfService/17-2.png'
import img17_3 from '../../assets/images/TermsOfService/17-3.png'
import img17_4 from '../../assets/images/TermsOfService/17-4.png'
import img18 from '../../assets/images/TermsOfService/18-Indemnity.png'
import img19_1 from '../../assets/images/TermsOfService/19-1.png'
import img19_2 from '../../assets/images/TermsOfService/19-2.png'
import img19_3 from '../../assets/images/TermsOfService/19-3.png'
import img19_4 from '../../assets/images/TermsOfService/19-4.png'
import img19_5 from '../../assets/images/TermsOfService/19-5.png'
import img20 from '../../assets/images/TermsOfService/20-LimitationOfLiability.png'
import img21_1 from '../../assets/images/TermsOfService/21-1.png'
import img21_2 from '../../assets/images/TermsOfService/21-2.png'
import img21_3 from '../../assets/images/TermsOfService/21-3.png'
import img21_4 from '../../assets/images/TermsOfService/21-4.png'
import img21_5 from '../../assets/images/TermsOfService/21-5.png'
import img21_6 from '../../assets/images/TermsOfService/21-6.png'
import img21_7 from '../../assets/images/TermsOfService/21-7.png'
import img21_8 from '../../assets/images/TermsOfService/21-8.png'
import img21_9 from '../../assets/images/TermsOfService/21-9.png'
import img21_10 from '../../assets/images/TermsOfService/21-10.png'
import img22_1 from '../../assets/images/TermsOfService/22-1.png'
import img22_2 from '../../assets/images/TermsOfService/22-2.png'
import img22_3 from '../../assets/images/TermsOfService/22-3.png'
import img22_4 from '../../assets/images/TermsOfService/22-4.png'
import img22_5 from '../../assets/images/TermsOfService/22-5.png'
import img22_6 from '../../assets/images/TermsOfService/22-6.png'
import img22_7 from '../../assets/images/TermsOfService/22-7.png'
import img22_8 from '../../assets/images/TermsOfService/22-8.png'
import img22_9 from '../../assets/images/TermsOfService/22-9.png'

const termsOfServiceData = [
    {
        title: '1. Overview',
        content: img1,
        subsections: [
            // { title: '1.1. Overview', content: 'This is the overview of the introduction.' },
            // { title: '1.2. Purpose', content: 'This describes the purpose of the introduction.' },
        ],
    },
    {
        title: '2. Eligibility',
        content: img2,
        subsections: [
            // { title: '2.1. Types of Data', content: 'This details the types of data collected.' },
            // { title: '2.2. Data Usage', content: 'This explains how the data is used.' },
        ],
    },
    {
        title: '3. Accounts and Registration',
        content: img3,
        subsections: [
            // { title: '3.1. Storage Duration', content: 'This describes how long data is retained.' },
            // { title: '3.2. Security Measures', content: 'This outlines the security measures in place.' },
        ],
    },
    {
        title: '4. General Payment Terms',
        content: img4,
        subsections: [
            { title: '4.1. Price', content: img4_1 },
            { title: '4.2. Authorization', content: img4_2 },
            { title: '4.3. Subscription Service', content: img4_3 },
            { title: '4.4. Payment Processing', content: img4_4 },
            { title: '4.5. Delinquent Accounts', content: img4_5 },
        ],
    },
    {
        title: '5. Licenses',
        content: '',
        subsections: [
            { title: '5.1. Limited License', content: img5_1 },
            { title: '5.2. License Restrictions', content: img5_2 },
            { title: '5.3. Feedback', content: img5_3 },
        ],
    },
    {
        title: '6. Ownership; Proprietary Rights',
        content: img6,
        subsections: [
            // { title: '6.1. What Are Cookies?', content: 'This describes what cookies are.' },
            // { title: '6.2. Managing Cookies', content: 'Instructions on how to manage cookies.' },
        ],
    },
    {
        title: '7. Third-Party Terms',
        content: '',
        subsections: [
            { title: '7.1. Third-Party Services and Linked Websites', content: img7_1},
            { title: '7.2. Third-Party Software', content: img7_2 },
            { title: '7.3. OpenAI Terms', content: img7_3 },
        ],
    },
    {
        title: '8. Artificial Intelligence',
        content: img8,
        subsections: [
            // { title: '8.1. Transfer Mechanisms', content: 'How data is legally transferred internationally.' },
            // { title: '8.2. Local Regulations', content: 'Compliance with local data regulations.' },
        ],
    },
    {
        title: '9. Input Content; Output Content',
        content: '',
        subsections: [
            { title: '9.1. Generally', content: img9_1 },
            { title: '9.2. Your Ownership Rights', content: img9_2 },
            { title: '9.3. Limited License Grant to GrantAide', content: img9_3 },
            { title: '9.4. Input Content Representations and Warranties', content: img9_4 },
            { title: '9.5. Content Disclaimer', content: img9_5 },
        ],
    },
    {
        title: '10. Prohibited Data',
        content: img10,
        subsections: [
            // { title: '10.1: Notification of Changes', content: 'How users will be notified of changes.' },
            // { title: '10.2: Version History', content: 'Record of previous versions of the policy.' },
        ],
    },
    {
        title: '11. Data Processing Addendum',
        content: img11,
        subsections: [
            // { title: '11.1. Support Contact', content: 'Contact information for support inquiries.' },
            // { title: '11.2. Privacy Officer', content: 'Contact details for the privacy officer.' },
        ],
    },
    {
        title: '12. Monitoring Content',
        content: img12,
        subsections: [
            // { title: '12.1. Data Limitation', content: 'How we limit data collection.' },
            // { title: '12.2. Purpose Specification', content: 'Defining purposes for data collection.' },
        ],
    },
    {
        title: '13. Communications',
        content: '',
        subsections: [
            { title: '13.1. Text Messaging & Phone Calls', content: img13_1 },
            { title: '13.2. Email', content: img13_2 },
            { title: '13.3. Chat Support', content: img13_3 },
        ],
    },
    {
        title: '14. Prohibited Conduct',
        content: img14,
        subsections: [
            // { title: '14.1. What is Automated Decision Making?', content: 'Explanation of automated decisions.' },
            // { title: '14.2. User Rights', content: 'User rights related to automated decisions.' },
        ],
    },
    {
        title: '15. Intellectual Property Rights Protection',
        content: '',
        subsections: [
            { title: '15.1. Respect of Third Party Rights', content: img15_1 },
            { title: '15.2. DMCA Notification', content: img15_2 },
            { title: '15.3. Procedure for Reporting Claimed Infringement', content: img15_3 },
            { title: '15.4. Repeat Infringers', content: img15_4 },
            { title: '15.5. Counter Notification', content: img15_5 },
            { title: '15.6. Reposting of Content Subject to a Counter Notification', content: img15_6 },
            { title: '15.7. False Notifications of Claimed Infringement or Counter Notifications', content: img15_7 },
        ],
    },
    {
        title: '16. Modification of Terms',
        content: img16,
        subsections: [
            // { title: '16.1. External Links', content: 'Disclaimer about external links.' },
            // { title: '16.2. Third-Party Policies', content: 'Encouragement to review third-party policies.' },
        ],
    },
    {
        title: '17. Term, Termination, and Modification of the Service',
        content: '',
        subsections: [
            { title: '17.1. Term', content: img17_1 },
            { title: '17.2. Termination', content: img17_2 },
            { title: '17.3. Effect of Termination', content: img17_3 },
            { title: '17.4. Modification of the Service', content: img17_4 },
        ],
    },
    {
        title: '18. Indemnity',
        content: img18,
        subsections: [
            // { title: '18.1. Consent Types', content: 'Different types of consent we require.' },
            // { title: '18.2. Revoking Consent', content: 'How users can revoke their consent.' },
        ],
    },
    {
        title: '19. Disclaimers; No Warranties by GrantAide',
        content: '',
        subsections: [
            { title: '19.1. No Guarantee of Grant', content: img19_1 },
            { title: '19.2. Disclaimers Related to Artificial Intelligence', content: img19_2 },
            { title: '19.3. ', content: img19_3 },
            { title: '19.4. ', content: img19_4 },
            { title: '19.5. ', content: img19_5 },
        ],
    },
    {
        title: '20. Limitaion of Liability',
        content: img20,
        subsections: [
            // { title: '20.1. GDPR Compliance', content: 'Overview of GDPR compliance measures.' },
            // { title: '20.2. CCPA Compliance', content: 'Overview of CCPA compliance measures.' },
        ],
    },
    {
        title: '21. Dispute Resolution and Arbitration',
        content: '',
        subsections: [
            { title: '21.1. Generally', content: img21_1 },
            { title: '21.2. Exceptions', content: img21_2},
            { title: '21.3. Opt-Out', content: img21_3},
            { title: '21.4. Arbitrator', content: img21_4},
            { title: '21.5. Commencing Arbitration', content: img21_5},
            { title: '21.6. Arbitration Proceedings', content: img21_6},
            { title: '21.7. Arbitration Relief', content: img21_7},
            { title: '21.8. No Class Actions', content: img21_8},
            { title: '21.9. Modifications to this Arbitration Provision', content: img21_9},
            { title: '21.10. Enforceability', content: img21_10},
        ],
    },
    {
        title: '22. Miscellaneous',
        content: '',
        subsections: [
            { title: '22.1. General Terms', content: img22_1 },
            { title: '22.2. Governing Law', content: img22_2},
            { title: '22.3. Privacy Policy', content: img22_3},
            { title: '22.4. Additional Terms', content: img22_4},
            { title: '22.5. Consent to Electronic Communications', content: img22_5},
            { title: '22.6. Contact Information', content: img22_6},
            { title: '22.7. Notice to California Residents', content: img22_7},
            { title: '22.8. No Support', content: img22_8},
            { title: '22.9. International Use', content: img22_9},
        ],
    },
];


function TermsOfService() {
    const [expandedSections, setExpandedSections] = useState({});
    const [expandedSubsections, setExpandedSubsections] = useState({});

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };
    
    const toggleSubsection = (section, subsection) => {
        setExpandedSubsections((prev) => ({
            ...prev,
            [`${section}-${subsection}`]: !prev[`${section}-${subsection}`],
        }));
    };

    return (
        <div className='terms-page'>
            <div className="terms-container">
                <h1 className="terms-heading">Terms of Service</h1>
                <img src={imgIntro} alt=''></img>
                {termsOfServiceData.map((section, sectionIndex) => (
                    <div className="terms-section" key={sectionIndex}>
                        <div
                            className="terms-subheading"
                            onClick={() => toggleSection(sectionIndex)}
                        >
                            <span>{section.title}</span>
                            <span className="symbol">{expandedSections[sectionIndex] ? "−" : "+"}</span>
                        </div>
                        {expandedSections[sectionIndex] && (
                            <div>
                                <img src={section.content} alt=''></img>
                                {section.subsections.map((subsection, subsectionIndex) => (
                                    <div key={subsectionIndex} className='ml-5'>
                                        <div
                                            className="terms-subsection-heading"
                                            onClick={() => toggleSubsection(sectionIndex, subsectionIndex)}
                                        >
                                            <span>{subsection.title}</span>
                                            <span className="symbol">{expandedSubsections[`${sectionIndex}-${subsectionIndex}`] ? "−" : "+"}</span>
                                        </div>
                                        {expandedSubsections[`${sectionIndex}-${subsectionIndex}`] && (
                                            <img src={subsection.content} alt='sadfs'>
                                            </img>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

        </div>
    );

}

export default TermsOfService;
