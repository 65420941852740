import React, { useState } from 'react';

import './TermsOfService.css'; 

const privacyPolicyData = [
    {
        title: 'Section 1: Introduction',
        content: 'This is the introduction paragraph for the Privacy Policy.',
        subsections: [
            { title: 'Subsection 1.1: Overview', content: 'This is the overview of the introduction.' },
            { title: 'Subsection 1.2: Purpose', content: 'This describes the purpose of the introduction.' },
        ],
    },
    {
        title: 'Section 2: Data Collection',
        content: 'This is the data collection paragraph.',
        subsections: [
            { title: 'Subsection 2.1: Types of Data', content: 'This details the types of data collected.' },
            { title: 'Subsection 2.2: Data Usage', content: 'This explains how the data is used.' },
        ],
    },
    {
        title: 'Section 3: Data Storage',
        content: 'This section explains how and where data is stored.',
        subsections: [
            { title: 'Subsection 3.1: Storage Duration', content: 'This describes how long data is retained.' },
            { title: 'Subsection 3.2: Security Measures', content: 'This outlines the security measures in place.' },
        ],
    },
    {
        title: 'Section 4: User Rights',
        content: 'This section covers user rights regarding their data.',
        subsections: [
            { title: 'Subsection 4.1: Access Rights', content: 'Users can request access to their data.' },
            { title: 'Subsection 4.2: Deletion Rights', content: 'Users have the right to request deletion of their data.' },
        ],
    },
    {
        title: 'Section 5: Data Sharing',
        content: 'This section discusses how and when data is shared with third parties.',
        subsections: [
            { title: 'Subsection 5.1: Third-Party Services', content: 'This outlines third-party services we may use.' },
            { title: 'Subsection 5.2: Legal Obligations', content: 'Data may be shared for legal reasons.' },
        ],
    },
    {
        title: 'Section 6: Cookies',
        content: 'This section explains the use of cookies on our site.',
        subsections: [
            { title: 'Subsection 6.1: What Are Cookies?', content: 'This describes what cookies are.' },
            { title: 'Subsection 6.2: Managing Cookies', content: 'Instructions on how to manage cookies.' },
        ],
    },
    {
        title: 'Section 7: Data Security',
        content: 'This section outlines our data security practices.',
        subsections: [
            { title: 'Subsection 7.1: Encryption', content: 'Details on encryption methods used.' },
            { title: 'Subsection 7.2: Incident Response', content: 'How we respond to data breaches.' },
        ],
    },
    {
        title: 'Section 8: International Data Transfers',
        content: 'This section discusses international data transfers.',
        subsections: [
            { title: 'Subsection 8.1: Transfer Mechanisms', content: 'How data is legally transferred internationally.' },
            { title: 'Subsection 8.2: Local Regulations', content: 'Compliance with local data regulations.' },
        ],
    },
    {
        title: 'Section 9: Children’s Privacy',
        content: 'This section discusses the privacy of children.',
        subsections: [
            { title: 'Subsection 9.1: Age Restrictions', content: 'Details on age restrictions for data collection.' },
            { title: 'Subsection 9.2: Parental Consent', content: 'How parental consent is obtained.' },
        ],
    },
    {
        title: 'Section 10: Changes to the Policy',
        content: 'This section explains how we update our privacy policy.',
        subsections: [
            { title: 'Subsection 10.1: Notification of Changes', content: 'How users will be notified of changes.' },
            { title: 'Subsection 10.2: Version History', content: 'Record of previous versions of the policy.' },
        ],
    },
    {
        title: 'Section 11: Contact Information',
        content: 'This section provides contact details for inquiries.',
        subsections: [
            { title: 'Subsection 11.1: Support Contact', content: 'Contact information for support inquiries.' },
            { title: 'Subsection 11.2: Privacy Officer', content: 'Contact details for the privacy officer.' },
        ],
    },
    {
        title: 'Section 12: Data Minimization',
        content: 'This section outlines our data minimization practices.',
        subsections: [
            { title: 'Subsection 12.1: Data Limitation', content: 'How we limit data collection.' },
            { title: 'Subsection 12.2: Purpose Specification', content: 'Defining purposes for data collection.' },
        ],
    },
    {
        title: 'Section 13: Data Retention',
        content: 'This section describes data retention policies.',
        subsections: [
            { title: 'Subsection 13.1: Retention Periods', content: 'How long different types of data are retained.' },
            { title: 'Subsection 13.2: Data Disposal', content: 'Methods for disposing of data securely.' },
        ],
    },
    {
        title: 'Section 14: Automated Decision Making',
        content: 'This section covers automated decision-making processes.',
        subsections: [
            { title: 'Subsection 14.1: What is Automated Decision Making?', content: 'Explanation of automated decisions.' },
            { title: 'Subsection 14.2: User Rights', content: 'User rights related to automated decisions.' },
        ],
    },
    {
        title: 'Section 15: Data Breach Procedures',
        content: 'This section explains our procedures for data breaches.',
        subsections: [
            { title: 'Subsection 15.1: Incident Reporting', content: 'How data breaches are reported.' },
            { title: 'Subsection 15.2: User Notification', content: 'How users are notified of breaches.' },
        ],
    },
    {
        title: 'Section 16: Links to Other Websites',
        content: 'This section discusses links to third-party websites.',
        subsections: [
            { title: 'Subsection 16.1: External Links', content: 'Disclaimer about external links.' },
            { title: 'Subsection 16.2: Third-Party Policies', content: 'Encouragement to review third-party policies.' },
        ],
    },
    {
        title: 'Section 17: Changes to User Data',
        content: 'This section discusses how users can change their data.',
        subsections: [
            { title: 'Subsection 17.1: Update Procedures', content: 'How users can update their information.' },
            { title: 'Subsection 17.2: Data Accuracy', content: 'Commitment to data accuracy.' },
        ],
    },
    {
        title: 'Section 18: Consent Management',
        content: 'This section explains how we manage user consent.',
        subsections: [
            { title: 'Subsection 18.1: Consent Types', content: 'Different types of consent we require.' },
            { title: 'Subsection 18.2: Revoking Consent', content: 'How users can revoke their consent.' },
        ],
    },
    {
        title: 'Section 19: Anonymization and Pseudonymization',
        content: 'This section discusses techniques used to protect data.',
        subsections: [
            { title: 'Subsection 19.1: Anonymization Techniques', content: 'Methods of anonymizing data.' },
            { title: 'Subsection 19.2: Pseudonymization Benefits', content: 'Benefits of pseudonymization.' },
        ],
    },
    {
        title: 'Section 20: Compliance with Laws',
        content: 'This section outlines our compliance with relevant laws.',
        subsections: [
            { title: 'Subsection 20.1: GDPR Compliance', content: 'Overview of GDPR compliance measures.' },
            { title: 'Subsection 20.2: CCPA Compliance', content: 'Overview of CCPA compliance measures.' },
        ],
    },
    {
        title: 'Section 21: Data Protection Impact Assessments',
        content: 'This section describes our impact assessments.',
        subsections: [
            { title: 'Subsection 21.1: Assessment Process', content: 'How we conduct data protection impact assessments.' },
            { title: 'Subsection 21.2: Risk Mitigation', content: 'Measures taken to mitigate identified risks.' },
        ],
    },
    {
        title: 'Section 22: Final Provisions',
        content: 'This section includes final provisions of the policy.',
        subsections: [
            { title: 'Subsection 22.1: Governing Law', content: 'The laws governing the policy.' },
            { title: 'Subsection 22.2: Severability', content: 'What happens if a provision is found invalid.' },
        ],
    },
];

function PrivacyPolicy() {
    const [expandedSections, setExpandedSections] = useState({});
    const [expandedSubsections, setExpandedSubsections] = useState({});

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };
    
    const toggleSubsection = (section, subsection) => {
        setExpandedSubsections((prev) => ({
            ...prev,
            [`${section}-${subsection}`]: !prev[`${section}-${subsection}`],
        }));
    };

    return (
        <div className='terms-page'>
            <div className="terms-container">
                <h1 className="terms-heading">Privacy Policy</h1>
                {privacyPolicyData.map((section, sectionIndex) => (
                    <div className="terms-section" key={sectionIndex}>
                        <div
                            className="terms-subheading"
                            onClick={() => toggleSection(sectionIndex)}
                        >
                            <span>{section.title}</span>
                            <span className="symbol">{expandedSections[sectionIndex] ? "−" : "+"}</span>
                        </div>
                        {expandedSections[sectionIndex] && (
                            <div>
                                <div className="terms-paragraph">{section.content}</div>
                                {section.subsections.map((subsection, subsectionIndex) => (
                                    <div key={subsectionIndex}>
                                        <div
                                            className="terms-subheading"
                                            onClick={() => toggleSubsection(sectionIndex, subsectionIndex)}
                                        >
                                            <span>{subsection.title}</span>
                                            <span className="symbol">{expandedSubsections[`${sectionIndex}-${subsectionIndex}`] ? "−" : "+"}</span>
                                        </div>
                                        {expandedSubsections[`${sectionIndex}-${subsectionIndex}`] && (
                                            <div className="terms-paragraph">
                                                {subsection.content}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );

}

export default PrivacyPolicy;
