import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser(state, action) {
            return action.payload;
        },
        logoutUser(state) {
            return null
        }
    },
});

export const { setUser, addUser, updateUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;