import LandingPage from "./Pages/Pre Launch Page/LandingPage";
import Main from "./Pages/Main";
import { Routes, Route } from "react-router-dom";
import GrantProfileForm from "./Pages/grantprofileform";
import ProposalDashboard from "./Pages/Dashboards/proposal-dashboard";
import Login from "./Pages/Registration/login";
import Signup from "./Pages/Registration/signup";
import NewProposalDashboard from "./Pages/Dashboards/new-proposal-dashboard";
import TermsOfService from "./Pages/Termspages/termsofservice";
import PrivacyPolicy from "./Pages/Termspages/privacypolicy";
import DataProcessingAgreement from "./Pages/Termspages/dataprocessing";
import { SubscriptionProvider } from "./Contexts/SubscriptionContext";
import AffiliatePage from "./Pages/Affiliate";
import { ToastContainer } from "react-toastify";
import useSaveAffiliateId from "./hooks/useSaveAfilliateId";
import ClientProposalDashboard from "./Pages/Dashboards/ClientProposalDashboard";
import ResetPassword from "./Pages/Registration/resetPassword";
import 'react-toastify/dist/ReactToastify.css';

function App() {

  useSaveAffiliateId();

  return (
    <div className="App">
      <ToastContainer />
      <SubscriptionProvider>
        <Routes>
          <Route exact path="/affiliate" element={<AffiliatePage />} />
          <Route exact path="/grant-assistant" element={<Main />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/proposal-dashboard" element={<ProposalDashboard />} />
          <Route exact path="/form" element={<GrantProfileForm />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/all-client-dashboard" element={<ClientProposalDashboard />} />
          <Route exact path="/new-proposal-dashboard" element={<NewProposalDashboard />} />
          <Route exact path="/termsofservice" element={<TermsOfService />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/dataprocessing"
            element={<DataProcessingAgreement />}
          />
        </Routes>
      </SubscriptionProvider>
    </div>
  );
}

export default App;
