import { GoogleGenerativeAI } from "@google/generative-ai";

export default async function parseInformation(data) {
  let format = {
    Grant_Proposal_Name: "",
    User_name: "",
    Company_Name: "",
    User_role: "",
    Company_Website: "",
    Mission_Statement: "",
    Vision_Statement: "",
    Target_Users_Market: "",
    Focused_Industry: "",
    Problem_Statement: "",
    Solution_Statement: "",
    Programs_Services: "",
    Track_record: "",
    Current_funding: "",
    Budget: "",
    Stakeholders: "",
    Product_Features: "",
    Goals_Objectives_Outcomes: "",
    Long_Term_Plan: "",
    Partners_Collaborators_1: "",
    Partners_Collaborators_2: "",

    Team_Member_1: "",
    Team_Member_Role_1: "",
    Team_Member_Education_1: "",
    Team_Member_1_LinkedIn: "",
    Team_Member_Interests_Hobbies_1: "",
    Team_Member_Super_Power_Skills_1: "",

    Team_Member_2: "",
    Team_Member_Role_2: "",
    Team_Member_Education_2: "",
    Team_Member_2_LinkedIn: "",
    Team_Member_Interests_Hobbies_2: "",
    Team_Member_Super_Power_Skills_2: "",

    Team_Member_3: "",
    Team_Member_Role_3: "",
    Team_Member_Education_3: "",
    Team_Member_3_LinkedIn: "",
    Team_Member_Interests_Hobbies_3: "",
    Team_Member_Super_Power_Skills_3: "",

    Team_Member_4: "",
    Team_Member_Role_4: "",
    Team_Member_Education_4: "",
    Team_Member_4_LinkedIn: "",
    Team_Member_Interests_Hobbies_4: "",
    Team_Member_Super_Power_Skills_4: "",

    Grant_Name: "",
    Grant_Company_Name: "",
    Grant_Amount: 0,
    Grant_Requirements: "",
    Due_Date: "",
    Due_Time: "",
    Due_Time_Zone: "",
    AM_PM: "",
    Grant_Company_Website: "",
    Grant_Application_Website: "",
    Grant_Company_Mission_Statement: "",
    Grant_Company_Vision_Statement: "",
    Grant_Company_Target_Market: "",
    Grant_Company_Category_Industry: "",
    Agency_Type: "",
    Geographic_Focus: "",
    Grant_Company_LinkedIn: "",

    Judges_Name: "",
    Judges_Role: "",
    Judges_Partnerships_Collaborations_1: "",
    Judges_Partnerships_Collaborations_2: "",
    Judges_LinkedIn: "",
    Judges_More_Relevant_info: "",
  };

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  // console.log("API Key:", apiKey);
  let prompt = `Extract the following information from the provided text: ${data}. Please return a JavaScript object in the following format: ${JSON.stringify(format)}. Each value must not exceed 15 words.`;

  /**
   * set up gemini api
   */
  const genAi = new GoogleGenerativeAI(apiKey);
  const model = genAi.getGenerativeModel({
    model: "gemini-1.5-flash",
    systemInstruction:
      "You are a helpful assistant designed to output JSON. The response should match the format exactly, with no additional text or explanations.",
  });

  try {
    const result = await model.generateContent(prompt);
    const finalText = result.response.candidates[0].content.parts[0].text;
    /**
     * File uploader was not populating form fields due this JSON format error.
     * The replace was not removing trailing ''' quotes which is how gemini returns json object wrapped as such: '''json {}'''
     * Added slice(0, -4) to remove what the reg expression was failing to do. 
     * We tried changing the regex but this reg ex is supposed remove the trailing ''' as well
     */
    const finalTextCleaned = finalText.replace(/^```json\n?|```$/g, "").slice(0, -4); 
    return finalTextCleaned
  } catch (e) {
    console.log("Failed to fetch data:", e);
    return null;
  }
}