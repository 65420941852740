import { React, useEffect, useState, useRef } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebase";
import FileUploader from "../Components/FileUploader";
import LinearProgress from "@mui/material/LinearProgress";
import WrapperPlan from "../Components/WrapperPlan";
import ChatInput from "../Components/ChatInput";
import TableOfContent from "../Components/TableOfContent";
import Rpfquestions from "../Components/Rpfquestions";
import ChatMessage from "../Components/ChatMessage";
import { Typography } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Document as docxDocument, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";  
import { gapi } from "gapi-script";  
import NavBar from "../Components/NavBar"; 
import DownwnloadModalOptions from "../Components/DownloadModal"; 

function Main() {
  const baseurl = process.env.REACT_APP_API_URL;
  const SCOPES = "https://www.googleapis.com/auth/drive.file";
  //this is the function that initializes the google console api all that needs to be changed is the client id 
  const initClient = () => {
    gapi.client.init({
      clientId:process.env.REACT_APP_CLIENT_ID_GOOGLE,
      scope: SCOPES,
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
      ux_mode: 'popup',
    }).then(() => {
  
    }, (error) => {
      console.error("Error initializing GAPI client:", error);
    });
  };
  
  useEffect(() => {
    gapi.load("client:auth2", initClient);
  }, []);

  const [showPdfLink, setShowPdfLink] = useState(false);
  const [pdfData, setpdfData] = useState();
  const [docxData, setDocxData] = useState([]);
  const [input, setInput] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [userQuestions, setUserQuestions] = useState([]);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sentformData, setSentformData] = useState(false);
  const [displayMode, setDisplayMode] = useState("words");
  const [wordCount, setWordCount] = useState(0);
  const [gptVersion, setGptVersion] = useState("gpt-4o-mini");
  const [charCount, setCharCount] = useState(0);
  const [activeTab, setActiveTab] = useState("tableOfContent");
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const user = auth.currentUser;
  const location = useLocation(); 
  let grantProposalForm_id, client_Organization_id, from;
  let profileName = location?.state?.formDetails?.Grant_Proposal_Name||'';
  if (location.state.from === "grantform") { 
    ({ from, grantProposalForm_id, client_Organization_id, profileName } = location.state || {});
  } else { 
    const { formDetails } = location.state || {};
    grantProposalForm_id = formDetails?.id;
    ({ client_Organization_id } = location.state || {});
  }
  const uid = user ? user.uid : null;

  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/login");
      }
    });
    populateChat();
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  useEffect(() => {
    const updatedUserQuestions = messageList
      .filter((msg) => msg.user === "user")
      .map((msg) => ({ ...msg, uid }));
    setUserQuestions(...updatedUserQuestions);
  }, [messageList]);

  if (!sentformData) {
    sendformData();
    setSentformData(true);
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const filteredAnswers = (userQuestions) => {
    if (!Array.isArray(userQuestions)) {
      return [];
    }
    return userQuestions.filter((question) => question.user === "user");
  };

  //converts the chat history to pdf
  const convertToPdf = (data) => {
    let counter = 0;

    const content = data.map((entry) => {
      if (entry.user === 'user') {
        counter++;
        return `${counter})  ${entry.message}`;
      }
      return `${entry.message}`;
    });

    const styles = StyleSheet.create({
      page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
      },
      text: {
        fontSize: 12,
        margin: 10
      }
    });
    setShowPdfLink(true);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            {content.map((text, index) => (
              <Text key={index} style={styles.text} >
                {containsMarkdown(text)}
              </Text>
            ))}
          </View>
        </Page>
      </Document>
    );
  };

  //converts the chat history to docx
  const convertToDocx = (data, upload) => {
    const doc = new docxDocument({
      sections: [
        {
          children: data.map((message) =>
            new Paragraph({
              children: [new TextRun(message.message)],
            })
          ),
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      if (upload) {
        authenticate().then(() => uploadFileToDrive(blob));//upload the docx file to google drive
      } else {
        saveAs(blob, `${profileName}.docx`); //if uncomments this line, it will download the docx file instead of uploading it to google drive
      }
    });
  };

  //authenticate the user
  const authenticate = () => {
    return gapi.auth2.getAuthInstance().signIn({
      scope: SCOPES,
    });
  };

  //upload the docx file to google drive
  const uploadFileToDrive = (blob) => {
    const metadata = {
      name: `${profileName}.docx`,
      mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    };

    const form = new FormData();
    form.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json" }));
    form.append("file", blob);

    fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${gapi.auth.getToken().access_token}`,
      }),
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully", data);
        const fileId = data.id;

        // Construct the file link
        const fileLink = `https://drive.google.com/file/d/${fileId}/view`;

        // Open the link in a new window
        window.open(fileLink, "_blank");
      })
      .catch((error) => console.error("Error uploading file", error));
  };

  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
  };

  const handleGptVersionChange = (version) => {
    setGptVersion(version);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  //function that removes markdown from text
  function containsMarkdown(text) {
    // Define regex patterns for common Markdown elements
    const markdownPatterns = [
      /(^|\s)(#{1,6}\s)/g,  // Headers (e.g., # Header)
      /(\*\*|__)(.*?)\1/g,  // Bold (e.g., **bold** or __bold__)
      /(\*|_)(.*?)\1/g,     // Italic (e.g., *italic* or _italic_)
      /(\s|^)(-|\*|\+)\s/g, // Unordered list (e.g., - item)
      /(\s|^)(\d+\.)\s/g,   // Ordered list (e.g., 1. item)
      /\[(.*?)\]\((.*?)\)/g  // Links (e.g., [text](url))
    ];

    // Function to remove Markdown from a single line
    function processLine(line) {
      markdownPatterns.forEach(pattern => {
        line = line.replace(pattern, ''); // Replacing Markdown with empty string
      });
      return line;
    }
    // Check if text contains multiple lines
    if (text.includes('\n') || text.includes('\r\n')) {
      return text.split(/\r?\n/).map(processLine).join('\n');
    } else {
      return processLine(text);
    }
  }

  function clearChat() {
    setMessageList([]);
  }

  async function populateChat() {
    const response = await fetch(`${baseurl}/chat-history`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: uid,
        client_organization_id: location.state?.currentClient?.client_id || location.state.clientID,
        grant_proposal_form_id: location.state?.formDetails?.id || location.state.grantProposalForm_id,
      }),
    });
    try {
      const data = await response.json();
      const transformData = (data) => {
        const transformed = [];
        console.log(data)
        data.map((item) => {
          if (item.question) {
            transformed.push({ user: "user", message: item.question });
          }
          if (item.answer) {
            transformed.push({ user: "bot", message: item.answer, question: item.question, timestamp: item.timestamp });
          }
        });
        setDocxData(transformed);
        setpdfData(convertToPdf(transformed));
        return transformed;
      };
      const transformedData = transformData(data);
      // const lastTenTexts = transformedData.slice(
      //   Math.max(transformedData.length - 10, 0)
      // );

      setMessageList((prevMessageList) => {
        const newMessages = transformedData.filter(
          (newMsg) =>
            !prevMessageList.some(
              (prevMsg) =>
                prevMsg.user === newMsg.user &&
                prevMsg.message === newMsg.message
            )
        );
        return [...prevMessageList, ...newMessages];
      });

      const updatedUserQuestions = messageList
        .filter((msg) => msg.user === "user")
        .map((msg) => ({ ...msg, uid }));
      setUserQuestions(...updatedUserQuestions);

      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    } catch (error) {
      console.log("error fetching chats: " + error.message);
    }
  }

  async function sendformData() {
    setWaitingForResponse(true);
    setIsLoading(true);
    const res = fetch(`${baseurl}/gptRun/form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: uid,
        currentForm: location.state.formData
      }),
    });
    setWaitingForResponse(false);
    setIsLoading(false);
  }

  async function handleSubmit(e) {
    // e.preventDefault();
    if (!waitingForResponse && input) {
      setWaitingForResponse(true);
      setIsLoading(true);
      let newMessageList = [
        ...messageList,
        { user: "user", message: `${input}` },
      ];
      setInput("");
      setMessageList(newMessageList);
  
      const response = await fetch(`${baseurl}/gptRun/aiapp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: input,
          uid: uid,
          profile: location.state?.currentClient?.organization_name || location.state.profileName,
          gptVersion: gptVersion,
          client_organization_id: location.state?.currentClient?.client_id || location.state.clientID,
          grant_proposal_form_id: location.state?.formDetails?.id || location.state.grantProposalForm_id,
          grant_proposal: location.state?.formDetails || location.state.formData
        }),
      });
      try {
        const data = await response.json(); 
        setMessageList([
          ...newMessageList,
          { user: "bot", message: `${data.message}` },
        ]);
          // Prepare `docxData` and `pdfData` from updated chat history.
        const updatedDocxData = [
          ...docxData,
          { user: "user", message: input },
          { user: "bot", message: data.message },
        ];
        setDocxData(updatedDocxData)
        setpdfData(convertToPdf(updatedDocxData))

        setWaitingForResponse(false);
        setIsLoading(false);

        newMessageList.forEach((msg, index) => {
          if (msg.user === "user") {
            setUserQuestions({ ...msg, uid: uid, index });
          }
        });
      } catch (error) {
        console.log("error: " + error.message);
        setWaitingForResponse(false)
        setIsLoading(false)
      }
    }
  }

  function handleRefreshClick() {
    console.log("Clicked outer refresh");
  }

  return (
    // <WrapperPlan autoOpen={true}> 
    <>
    <NavBar />

      <div className="dash-2 top-12 flex">
        <div className="w-1/5 bg-[#11002e] border-[#5406D2]">
          <div className="text-center align-middle justify-center mt-5 text-xl font-semibold">
            <h1>{profileName}</h1>
          </div>
          <div className="flex flex-col text-sm text-nowrap justify-center lg:flex-row">
            <button
              className={`mt-4 py-2 w-full duration-200 break-words break-normal border-b-8 border-[#8743cb] ${activeTab === "tableOfContent" ? "bg-[#8743cb]" : "bg-[#2C0075]"
                } rounded-t-lg`}
              onClick={() => handleTabChange("tableOfContent")}
            >
              Table of Content
            </button>
            {/* <button
              className={`mt-4 py-2 w-full duration-200 break-words break-normal border-b-8 border-[#8743cb] ${activeTab === "rpfQuestions" ? "bg-[#8743cb]" : "bg-[#2C0075]"
                } rounded-t-lg`}
              onClick={() => handleTabChange("rpfQuestions")}
            >
              Copilot
            </button> */}
          </div>
          <div className="duration-500">
            {activeTab === "tableOfContent" ? (
              <TableOfContent
                uid={uid}
                userQuestions={filteredAnswers(messageList)}
              />
            ) : (
              null
              // <Rpfquestions userQuestions={messageList} />
            )}
          </div>
        </div>
        <div className="chatbox w-3/5 h-full border-r-8 border-[#400080] bg-[#11002E] mr-5 relative rounded-lg">
          <div className="chat-log w-[97%] flex flex-col overflow-y-scroll scroll-smooth custom-scrollbar">
            {messageList.map((message, index) => {
              const isLastMessage = index === messageList.length - 1;
              return (
                <div id={message.user === 'user' ? `tab-${index / 2}` : ``} >
                  <ChatMessage
                    message={message}
                    parentRefreshClick={handleRefreshClick}
                    isLoading={isLoading}
                    displayMode={displayMode}
                    wordCount={wordCount}
                    charCount={charCount}
                    handleDisplayModeChange={handleDisplayModeChange}
                    showCount={true}
                    user_id = {uid}
                    client_organization_id = {location.state?.currentClient?.client_id || location.state.clientID}
                    grant_proposal_form_id ={location.state?.formDetails?.id || location.state.grantProposalForm_id}
                  />
                  {isLoading && isLastMessage ? (
                    <LinearProgress
                      color="secondary"
                      className="mt-[-16.25px]"
                    />
                  ) : null}
                </div>
              );
            })}
            {messageList.length === 0 &&
              <div className="text-center text-white mt-20">
                <Typography variant="h3" gutterBottom>
                  Add your grant questions below
                  <br />
                  And Let Us Do The Rest
                </Typography>
                <Typography variant="h6">
                  Also Type <Link href="#" underline="always">/</Link> to access special AI features
                </Typography>
                {/* <Typography variant="body2">
                  for example type <Link href="#" underline="always">@RFP</Link> to chat with the RFP
                </Typography> */}
              </div>
            }
            <div ref={messagesEndRef} />
          </div>
          <div className="fixed inset-x-0 bottom-0 flex justify-center">
            <ChatInput
              input={input}
              setInput={setInput}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              handleDisplayModeChange={handleDisplayModeChange}
              displayMode={displayMode}
              wordCount={wordCount}
              charCount={charCount}
              handleGptVersionChange={handleGptVersionChange}
            />
          </div>
        </div>
        <div className="w-1/6 flex  flex-col items-center ">
          <div className="">
            <FileUploader 
            from={"main"}
              client_Organization_id={location?.state?.currentClient?.client_id || location.state.clientID}
              grantProposalForm_id={location?.state?.formDetails?.id || location.state.grantProposalForm_id}  
            uid={uid}
            />
          </div>

          <div className="z-50 bg-[#380093] border border-[#5406D2] text-sm absolute bottom-8 px-4 py-1 rounded-lg">
            <DownwnloadModalOptions
              pdfData={pdfData}
              pdfProfileName={profileName}
              docx={() => { convertToDocx(docxData, false)}}
              drive={() => convertToDocx(docxData, true)}
              disabled={!docxData || docxData.length === 0}
            ></DownwnloadModalOptions>
          </div>


        </div>
      </div>
      </>
    // </WrapperPlan>
  );
} 

export default Main; 