import React, { useState } from 'react';
import './TermsOfService.css'; 

function DataProcessingAgreement() {
    const [expandedSections, setExpandedSections] = useState({});

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    return (
        <div className="terms-container">
            
            <h1 className="terms-heading">Data Processing Agreement</h1>
            <div className="terms-section">
                <div
                    className="terms-subheading"
                    onClick={() => toggleSection('section1')}
                >
                     <span>Section 1: Introduction</span>
                    <span className="symbol">{expandedSections['section1'] ? "−" : "+"}</span>
                </div>
                {expandedSections['section1'] && (
                    <div className="terms-paragraph">
                        This is the introduction paragraph for the Data Processing Agreement.
                    </div>
                )}
            </div>
            <div className="terms-section">
                <div
                    className="terms-subheading"
                    onClick={() => toggleSection('section2')}
                >
                     <span>Section 2: Data Processing Terms</span>
                    <span className="symbol">{expandedSections['section2'] ? "−" : "+"}</span>
                </div>
                {expandedSections['section2'] && (
                    <div className="terms-paragraph">
                        This is the data processing terms paragraph.
                    </div>
                )}
            </div>
            {/* Add more sections as needed */}
        </div>
    );
}

export default DataProcessingAgreement;
