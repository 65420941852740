import React from "react";
import { Container, Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(228.05deg, #81f8bf 12.76%, #3c88f1 35.58%, #8749e7 63.06%, #cb1ccd 89.1%)",
        color: "#fff",
        py: 3,
      }}
    >
      <Container>
        <Typography
          sx={{ fontWeight: "bold" }}
          variant="h6"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gutterBottom
        >
          GrantAIde
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Fast & Easy Grant Writing
        </Typography>

        <Typography variant="body2" align="center"></Typography>
        <Box textAlign="center" mt={2}>
          {/* <Link href="#" color="inherit" underline="hover" mx={1}>
            Home
          </Link>
          <Link href="#" color="inherit" underline="hover" mx={1}>
            About
          </Link>
          <Link href="#" color="inherit" underline="hover" mx={1}>
            Contact
          </Link>
          <Link href="/privacypolicy" color="inherit" underline="hover" mx={1}>
            Privacy Policy
          </Link>
          <Link href="/termsofservice" color="inherit" underline="hover" mx={1}>
            Terms of Service
          </Link>{" "} */}
          © {new Date().getFullYear()} GrantAIde
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
