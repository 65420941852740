import React, { useState } from "react";
import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { auth, db } from "../../utils/firebase";
import { addDoc, collection } from "firebase/firestore";

function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentError, setCurrentError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [acceptButtonClicked, setAcceptButtonClicked] = useState(false)
  const [showAcceptMessage, setShowAcceptMessage] = useState(false)
  const baseurl = process.env.REACT_APP_API_URL;

  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get("plan");
  const priceId = queryParams.get("price_id");
  const affiliateId = queryParams.get("affiliate_id");

  const handleAcceptClicked = () => {
    setShowAcceptMessage(false)
    setAcceptButtonClicked(!acceptButtonClicked)
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!acceptButtonClicked) {
      setShowAcceptMessage(true);
      alert('You must accept the terms and conditions to proceed.');
      return;
    }

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        try {
          await addDoc(collection(db, "UsersPersonalInfo"), {
            email: email,
            phone: phone,
            name: name,
            id: userCredential.user.uid,
          });

          // Check if payment session details are in query params
          if (plan && priceId) {
            const body = {
              user_id: userCredential.user.uid,
              price_id: priceId,
            };

            // Include affiliate_id only if it is defined
            if (affiliateId) {
              body.affiliate_id = affiliateId;
            }

            // Create payment session
            const response = await fetch(`${baseurl}/create_payment_session`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(body),
            });

            const data = await response.json();
            if (data.url) {
              // Redirect to Stripe checkout page
              window.location.assign(data.url);
            } else {
              console.error("Error: Payment URL not found");
            }
          } else {
            navigate("/form");
          }
        } catch (e) {
          console.error("Error adding user data or creating session:", e);
          await deleteUser(userCredential.user); // Rollback user creation if any error occurs
        }
      })
      .catch((error) => {
        console.log("Error during user creation:", error);
        setCurrentError(error);
      });
  }

  return (
    <div className="login-page">
      <div className="wrapper">
        <div className="title">Sign up</div>
        <div>
          {currentError && <p className="text-center text-red-700  my-2">{currentError.code.replace('auth/', '') === "weak-password" ? `Password should be at least 6 characters` : `${currentError.code.replace('auth/', '')}`}</p>}
        </div>
        <form onSubmit={onSubmit}>
          <div className="field">
            <input
              type="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="field">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Email Address</label>
          </div>

          <div className="field">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
          </div>
          {/* Conditional rendering based on password length */}
          {password.length < 6 && password.length > 0 && (
            <p className="text-center text-yellow-700 text-sm">Password should be at least 6 characters</p>
          )}

          <div className="field">
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <label htmlFor="phone">Phone</label>
          </div>
          <div className="field">
            <input type="submit" id="submit SignUp" value="Sign Up" />
          </div>
          <div className="terms">
            <label htmlFor="remember-me">
              By signing up, you agree to the{" "}
              <Link to="/termsofservice" target="_blank" className="font-bold underline text-[#6d56cc]">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link to="/privacypolicy" target="_blank" className="font-bold underline text-[#6d56cc]">
                Privacy Policy
              </Link>
            </label>
          </div>

          <div className="flex flex-col justify-center p-3">
            <label className="flex flex-row justify-center gap-2">
              I Accept
              <input
                type="checkbox"
                checked={acceptButtonClicked}
                onChange={handleAcceptClicked}
              />
            </label>
            {showAcceptMessage && <p className="flex flex-row justify-center text-red-700 font-medium text-md animate-pulse">Click accept to sign up.</p>}
          </div>

          <div className="signup-link">
            Already Registered?{" "}
            <Link to="/login">
              <a href="#">Login </a>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;


//useSaveAffiliateId() called in App.js not setting the affiliate_id