import { useState } from "react";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../utils/firebase";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./login.css";

function ResetPassword() {
    const [email, setEmail] = useState("");
    const [currentError, setCurrentError] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setCurrentError(null); // Clear any previous errors
        setSuccessMessage(""); // Clear previous success message
        try {
            await sendPasswordResetEmail(auth, email);
            setSuccessMessage("Password reset link sent successfully. Check your email.");
        } catch (error) {
            setCurrentError(error);
        }
    };

    return (
        <div className="login-page">
            <div className="wrapper">
                <div className="title">Reset Password</div>
                <div>
                    {currentError && (
                        <p className="text-center text-red-700 my-2">
                            {currentError.message}
                        </p>
                    )}
                    {successMessage && (
                        <p className="text-center text-green-700 my-2">{successMessage}</p>
                    )}
                </div>
                <div className="flex flex-col justify-center items-center w-full mt-6 ">
                    <p className="text-2xl font-extrabold">Forgot Password?</p>
                    <p className="text-gray-800">No worries, we'll send you reset instructions.</p>
                </div>
                <form onSubmit={handleResetPassword}>
                    <div className="field">
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label htmlFor="email">Email Address</label>
                    </div>

                    <div className="field">
                        <input type="submit" id="submit Login" value="Send" />
                    </div>
                    <div className="signup-link">
                        <Link to="/login">
                            <ArrowBackIcon /> Back To Login
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;