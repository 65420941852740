import { React, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { marked } from "marked";

export default function ChatMessage({
  message,
  parentRefreshClick,
  isLoading,
  displayMode,
  wordCount,
  charCount,
  handleDisplayModeChange,
  showCount,
  from,
  user_id,
  client_organization_id,
  grant_proposal_form_id
}) {
  const [messageRefreshed, setMessageRefreshed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tabList, setTabList] = useState([]);
  const [messageText, setMessageText] = useState(message.message);
  const messageRef = useRef(null);
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const [currentCharCount, setCurrentCharCount] = useState(0);
  const baseurl = process.env.REACT_APP_API_URL;

  //functions
  const calculateWNS = (message) => {
    const words = message.trim().split(/\s+/);
    const characters = message.trim().length;
    setCurrentWordCount(words.length);
    setCurrentCharCount(characters);
  };

  const handleContentInput = (e) => {
    calculateWNS(e.target.innerText);
  };

  const updateSelected = (selectedIdx) => {
    setTabList((prev) => {
      let newList = [...prev];
      let newObj = { selected: false };
      newList = newList.slice(selectedIdx, 1, newObj);
      return newList;
    });
  };

  const refreshClick = (e) => {
    parentRefreshClick();
    setMessageRefreshed(true);
    setTabList((prev) => {
      if (prev) {
        prev.map((elementInPrev) => {
          if (elementInPrev) {
            elementInPrev.selected = false;
          }
        });
        return [...prev, { selected: true }];
      }
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (messageRef.current) {
        messageRef.current.contentEditable = true;
        messageRef.current.focus();
      }
    }, 0);
  };

  const handleSaveClick = async () => {
    if (message.user === "bot") {
      const updatedMessage = messageRef.current.innerText.trim(); 
      setMessageText(updatedMessage);
  
      try {
        const response = await fetch(`${baseurl}/update-chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user_id,
            client_id: client_organization_id,
            proposal_id: grant_proposal_form_id,
            message: updatedMessage,
            question: message.question,
            timestamp: message.timestamp,
          }),
        });
  
        const data = await response.json();
  
        if (data.success) {
          console.log("Message updated successfully");
          setIsEditing(false);
        } else {
          console.error("Failed to update chat:", data.error);
        }
      } catch (error) {
        console.error("Error updating message:", error);
      }
    }
  };
  
  const handleCancelClick = () => {
    setIsEditing(false);
    setMessageText(message.message);
    messageRef.current.contentEditable = false;
  };

  const renderMessage = () => {
    if (message.user === "bot") {
      return (
        <div dangerouslySetInnerHTML={{ __html: marked(message.message) }} />
      );
    }
    return <p>{message.message}</p>;
  };

  //UseEffects
  useEffect(() => {
    if (messageRef.current && message.user === "bot") {
      messageRef.current.focus();
    }
  }, [message.user]);

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message.message]);

  useEffect(() => {
    calculateWNS(message.message); 
  }, [message.message]);

  return (
    <div
      className={`chat-message ${message.user === "bot" && "chatgpt overscroll-contain scroll-smooth custom-scrollbar"
        } w-full`}
    >
      <div className="chat-message-center overscroll-contain w-full scroll-smooth custom-scrollbar">
        <div className="avatar-and-btns-div">
          {from != "copilot" && <>
            <div
              className={`avatar ${message.user === "bot" &&
                "chatgpt overscroll-contain scroll-smooth custom-scrollbar"
                }`}
            ></div>
            {message.user === "bot" && showCount && (
              <div className="word-char-count-container">
                <div className="flex flex-col gap-1 word-char-count-btn">
                  <button
                    className="word-count"
                    onClick={() => handleDisplayModeChange("words")}
                  >
                    w
                  </button>
                  <button
                    className="character-count"
                    onClick={() => handleDisplayModeChange("characters")}
                  >
                    c
                  </button>
                </div>
                <div className="mt-1">
                  <span className="total-number-field">
                    {displayMode === "words"
                      ? currentWordCount
                      : currentCharCount}
                  </span>
                </div>
              </div>
            )}
          </>}
        </div>
        {message.user === "bot" && messageRefreshed && (
          <div className="tab-bar-div w-full">
            {tabList.map((tab, idx) => {
              if (tab) {
                return (
                  <div
                    onClick={() => updateSelected(idx)}
                    key={idx}
                    className={classNames("tab", tab.selected && "selected")}
                  />
                );
              }
            })}
          </div>
        )}
        <div>
        <div
          className="message overscroll-contain break-normal break-words w-full max-w-full scroll-smooth custom-scrollbar" 
          suppressContentEditableWarning={true} 
          id="contentEditableDiv"
          ref={messageRef}
          onInput={handleContentInput}
        >
          {renderMessage()}
        </div>
        {message.user === "bot" && (
        <div className="message-controls">
          {!isEditing ? (
            <button className="edit-button text-green-500 underline px-4 py-1" onClick={handleEditClick}>
              Edit
            </button>
          ) : (
            <div className="edit-controls flex gap-2 mt-2">
              <button className="save-button text-green-500 underline px-4" onClick={handleSaveClick}>
                Save
              </button>
              <button className="cancel-button text-red-500 underline px-4" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          )}
        </div>
        )}
        </div>
      </div>
    </div>
  );
};