import { createSlice } from '@reduxjs/toolkit';

const clientsSlice = createSlice({
  name: 'clients',
  initialState: { 'userId': null },
  reducers: {
    setClients(state, action) {
      const userId = localStorage.getItem('user_id')
      const client = action.payload

      if (!userId) {
        console.warn('User id not in local storage')
        return
      }

      if (Object.keys(client).length === 0) {
        console.warn('Client object is empty')
        return
      }

      const userIDString = userId.toString()
      state[userId] = client
      state['userId'] = userIDString
    },
    addClient(state, action) {
      const userId = localStorage.getItem('user_id');
      const client = action.payload;

      if (!userId) {
        console.warn('User ID not in local storage');
        return;
      }

      if (Object.keys(client).length === 0) {
        console.warn('Client object is empty');
        return;
      }

      const userIDString = userId.toString();

      // If userId already exists in state, add the client to the user's clients
        state[userIDString] = {
          ...state[userIDString],
          [client.client_id]: client  // Add new client with client_id as key
        };
      
      state['userId'] = userIDString;  // Update the userId reference in state
    },
    updateClient(state, action) {
      const { id, newData } = action.payload;
      const index = state.findIndex(client => client.id === id);
      if (index !== -1) {
        state[index] = { ...state[index], ...newData };
      }
    },
    updateProposalInfo(state, action) {
      const userId = localStorage.getItem('user_id');
      if (!userId) {
        console.warn('User id not in local storage');
        return;
      }
      const { clientID, formData, grantProposalForm_id } = action.payload;
    
      // Function to deep clone and serialize the formData
      const serializeFormData = (data) => {
        return JSON.parse(JSON.stringify(data, (key, value) => {
          if (value instanceof Date) {
            return value.toISOString(); // Convert Date to ISO string
          }
          return value;
        }));
      };
    
      const serializedFormData = serializeFormData(formData);
    
      const proposalWithId = {
        ...serializedFormData,
        id: grantProposalForm_id
      };

      // Check if the state contains the clientId for the current user
      if (state[userId] && state[userId][clientID]) {
        // Append the proposal to the proposals array
        if (!state[userId][clientID].proposals) {
          state[userId][clientID].proposals = [];
        }
        state[userId][clientID].proposals.push(proposalWithId);
      } else {
        throw new Error(`Client ID ${clientID} not found for user ID ${userId}`);
      }
      
    },
    logoutClients(state) {
      return { userId: null }; // Reset to initial state for clients
    },
  },
});

export const { setClients, addClient, updateClient, updateProposalInfo, logoutClients } = clientsSlice.actions;
export default clientsSlice.reducer;
