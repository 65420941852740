import React, { useState } from "react";
import {
  Container,
  Grid,
  Switch,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  List,
  Tooltip,
  ListItem,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase"; // Ensure you have Firebase configured

const baseurl = process.env.REACT_APP_API_URL; // Replace this with your base URL

const PricingPlans = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const navigate = useNavigate();

  const handleSwitchChange = () => {
    setIsMonthly(!isMonthly);
  };

  const plans = [
    {
      title: "Basic",
      price: isMonthly ? "$198/month" : "$1890/year",
      tag: "Starter",
      items: [
        "AI Grant Proposal Writer",
        "AI RFP Response Writer",
        "Unlimited Proposals",
        "Unlimited Proposal Templates",
        "15 AI Interactions / month",
        "10 Upload Documents / month",
        "Export Grants To PDF",
      ],
      monthlyPriceId: "price_1QG1EQL8ltvzfICgHwtVtU2Y",
      yearlyPriceId: "price_1QG1NqL8ltvzfICgWTYxb4Ve",
    },
    {
      title: `Agency`,
      price: isMonthly ? "$179/month" : "$249/year",
      tag: "Unlimited Client Accounts",
      items: [
        "Unlimited Clients",
        "Grant Search Database",
        "Unlimited Industry Report",
        "AI Grant Proposal Writer",
        "AI RFP Response Writer",
        "Unlimited Proposals",
        "Unlimited Proposal Templates",
        "Unlimited AI Interactions",
        "Unlimited Document Uploads",
        "Export Grants To PDF & DOCX",
      ],
      monthlyPriceId: "price_id_monthly_agency",
      yearlyPriceId: "price_id_yearly_agency",
    },
  ];

  const handlePlanSelection = async (plan) => {
    if (!auth.currentUser) {
      const priceId = isMonthly ? plan.monthlyPriceId : plan.yearlyPriceId;
      const affiliateId = localStorage.getItem('affiliate_id');
      const params = new URLSearchParams({
        plan: plan.title,
        price_id: priceId,
      });
  
      if (affiliateId) {
        params.append('affiliate_id', affiliateId);
      }
  
      // Redirect to signup page with relevant query parameters
      window.location.assign(`/signup?${params.toString()}`);
      return;
    }
  };


  return (
    <Container>
      <Box textAlign="center" mb={4} mt={2}>
        <Typography sx={{ color: "#fb4cff" }} variant="h4">
          Choose A Plan
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Typography color='white' variant="h6">Monthly</Typography>
          <Switch
            checked={isMonthly}
            onChange={handleSwitchChange}
            sx={{
              "& .MuiSwitch-switchBase": {
                "&.Mui-checked": {
                  color: "#3c88f1",
                  "& + .MuiSwitch-track": {
                    backgroundColor: "#3c88f1",
                  },
                },
              },
              "& .MuiSwitch-track": {
                backgroundColor: "#3c88f1",
              },
            }}
          />
          <Typography color='white' variant="h6">Annually</Typography>
        </Box>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: "100%",
                width: "360px",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                border: "5px solid #2355bf",
                borderRadius: "11px",
                boxShadow: "4px 4px 5px lightblue",
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    color: "#00068e",
                    fontFamily: "inherit",
                  }}
                >
                  {plan.title === 'Agency' ? (
                    <>
                      {plan.title}
                      <Tooltip
                        placement="top"
                        title={<Box component="span" sx={{ fontSize: 14 }}>Manage your first 4 Clients for FREE. Then $149 after.</Box>}
                      >
                        <InfoIcon sx={{ mr: 2 }} fontSize="large" className="blackIcon ml-2" />
                      </Tooltip>
                    </>
                  ) : (
                    plan.title
                  )}
                </Typography>
                <Typography display="flex" justifyContent="center" alignItems="center" gutterBottom>
                  {plan.tag}
                </Typography>
                <Typography
                  variant="h5"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gutterBottom
                  sx={{ color: "#00068e" }}
                >
                  {plan.title === "Agency" ? "" : plan.price}
                </Typography>
                <Box textAlign="center" pb={3}>
                  {plan.title === "Agency" ? (
                    <Button
                      component="a"
                      href="https://calendly.com/dream-big-/90min"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="contained"
                      size="large"
                      sx={{
                        backgroundColor: "#3c88f1",
                        color: "#fff",
                        width: "100%",
                        height: "55px",
                        textDecoration: "none", // Ensure link looks like a button
                      }}
                    >
                      Book a Demo
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        backgroundColor: "#3c88f1",
                        color: "#fff",
                        width: "100%",
                        height: "55px",
                      }}
                      onClick={() => handlePlanSelection(plan)}
                    >
                      Choose Plan
                    </Button>
                  )}
                </Box>
                <List>
                  {plan.items.map((item, idx) => (
                    <ListItem key={idx} sx={{ color: "#7e00ff" }}>
                      {item}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingPlans;
